<template>
    <modal-dialog
        id="billables-export-dialog"
        :show.sync="shown"
        :dismissable="true"
    >
        <template v-slot:title>
            <span class="h5">{{ $root.translate("Export SKUs") }}</span>
        </template>

        <form-date-range-input
            id="billables-export-date-range"
            name="range"
            v-model="range"
            :max="today"
            :required="true"
            :presets="presets"
            class="text-center"
        >
            <template v-slot:label>{{ translate("Select a date range:") }}</template>
        </form-date-range-input>

        <template v-slot:footer>
            <div class="text-center">
                <default-button
                    :class="{ 'mr-2': !!range }"
                    @click.prevent="shown = false"
                >
                    {{ $root.translate("Cancel") }}
                </default-button>
                <base-link
                    v-if="!!range"
                    :href="export_url"
                    target="export"
                    class="btn btn-primary ml-2"
                    @click="shown = false"
                >
                    {{ $root.translate("Download") }}
                </base-link>
            </div>
        </template>
    </modal-dialog>
</template>

<script>
import {
    format,
    startOfMonth,
    subMonths,
    subDays
} from "date-fns"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import BaseLink from "@/nibnut/components/Links/BaseLink"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import FormDateRangeInput from "@/custom/components/FormDateRangeInput"

export default {
    name: "BillablesExportDialog",
    components: {
        ModalDialog,
        BaseLink,
        DefaultButton,
        FormDateRangeInput
    },
    watch: {
        show: "reset"
    },
    methods: {
        reset () {
            const today = this.today
            if(today.getDate() < 15) this.range = { ...this.filters.last.range }
            else this.range = { ...this.filters.current.range }
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        today () {
            return new Date()
        },
        filters () {
            const today = this.today
            return {
                last: { title: this.translate("Last Month"), range: { to: subDays(startOfMonth(today), 1), from: subMonths(startOfMonth(today), 1) } },
                current: { title: this.translate("This Month"), range: { to: today, from: startOfMonth(today) } }
            }
        },
        presets () {
            const filters = this.filters
            return [
                filters.last,
                filters.current
            ]
        },
        export_url () {
            return `/export/billables?from=${format(this.range.from, "yyyy-MM-dd")}&to=${format(this.range.to, "yyyy-MM-dd")}&_x=${(new Date()).getTime()}`
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            range: null
        }
    }
}
</script>

<style lang="scss">
#billables-export-dialog {
    .modal-body {
        overflow-y: visible;
    }
}
</style>
