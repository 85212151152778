<template>
    <span v-if="asTime">
        <span v-if="total_hours">{{ hour_value }} h </span>
        <span v-if="total_minutes">{{ minute_value }} min </span>
        <span v-else>&lt; 1 min </span>
    </span>
    <span v-else>{{ number_value }}</span>
</template>

<script>
const TWEEN = require("@tweenjs/tween.js")

export default {
    name: "CountUpNumber",
    mounted () {
        this.tween_group = new TWEEN.Group()

        const animate = (time) => {
            requestAnimationFrame(animate)
            this.tween_group.update(time)
        }
        requestAnimationFrame(animate)

        this.reset()
    },
    watch: {
        from: "reset",
        to: "reset"
    },
    methods: {
        reset () {
            if(this.tween) this.tween.stop()
            if(this.to) {
                this.value = this.from
                const tween_value = { value: this.value }
                this.tween = new TWEEN.Tween(tween_value, this.tween_group)
                    .to({ value: this.to }, this.duration)
                    .easing(TWEEN.Easing.Cubic.InOut)
                    .onUpdate(() => {
                        this.value = Math.round(tween_value.value)
                    })
                    .start()
            }
        }
    },
    computed: {
        number_value () {
            return this.$numeral(this.value).format("0,0")
        },
        total_hours () {
            return Math.floor((this.to / 1000 / 60 / 60) % 24)
        },
        total_minutes () {
            return Math.floor((this.to / 1000 / 60) % 60)
        },
        hour_value () {
            const hours = Math.floor((this.value / 1000 / 60 / 60) % 24)
            return this.$numeral(hours).format("0,0")
        },
        minute_value () {
            return Math.floor((this.value / 1000 / 60) % 60)
        }
    },
    props: {
        from: {
            type: Number, // in milliseconds
            default: 0
        },
        to: {
            type: Number,
            required: true
        },
        asTime: {
            type: Boolean,
            default: false
        },
        duration: {
            type: Number,
            default: 3000
        }
    },
    data () {
        return {
            tween_group: null,
            tween: null,
            value: 0
        }
    }
}
</script>
