<template>
    <div>
        <modal-dialog
            id="client-service-link-dialog"
            :show.sync="shown"
            :dismissable="true"
        >
            <template v-slot:title>
                <span v-if="!!client && !!service && service.uses_custom_urls" class="h5">{{ $root.translate("{name}'s API credentials on {service}", { name: client.name, service: service.name }) }}</span>
                <span v-else-if="!!client && !!service" class="h5">{{ $root.translate("{name} on {service}", { name: client.name, service: service.name }) }}</span>
            </template>

            <div v-if="!!client && !!service && service.uses_custom_urls">
                <form-input
                    id="query"
                    name="query"
                    v-model="query"
                    :required="true"
                >
                    <template v-slot:label>
                        {{ service_remote_name_label }}
                    </template>
                </form-input>
                <form-input
                    id="remote_id"
                    name="remote_id"
                    v-model="remote_id"
                    :required="true"
                >
                    <template v-slot:label>
                        {{ service_remote_id_label }}
                    </template>
                </form-input>
            </div>
            <div v-else-if="!!client && !!service">
                <p>{{ $root.translate("Link to an existing {service} {entity}", { service: service.name, entity: service_entity }) }}:</p>
                <form-input
                    id="query"
                    name="query"
                    v-model="query"
                    :required="false"
                >
                    <template v-slot:right_addon>
                        <default-button
                            color="primary"
                            :disabled="waiting"
                            :waiting="waiting"
                            class="input-group-btn"
                            @click.prevent="search"
                        >
                            <open-icon glyph="search" />
                        </default-button>
                    </template>
                </form-input>
                <default-button
                    v-if="!companies.length"
                    :block="true"
                    :disabled="true"
                >
                    {{ $root.translate("No {entity} match on {service}", { entity: this.service_entity, service: service.name }) }}
                </default-button>
                <div v-else>
                    <default-button
                        v-for="company in companies"
                        :key="company.id"
                        :block="true"
                        :color="(remote_id === company.id) ? 'primary' : 'regular'"
                        class="mb-2"
                        @click.prevent="remote_id = company.id"
                    >
                        {{ company.name }}
                    </default-button>
                </div>

                <div v-if="canCreate" class="divider text-center" :data-content="$root.translate('OR')"></div>

                <default-button
                    v-if="canCreate"
                    :block="true"
                    :color="(remote_id === '__new__') ? 'primary' : 'regular'"
                    class="mt-2"
                    @click.prevent="remote_id = '__new__'"
                >
                    {{ $root.translate("Create a new {service} {entity}", { service: service.name, entity: service_entity }) }}
                </default-button>
            </div>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        :disabled="saving()"
                        class="mr-2"
                        @click.prevent="close"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        color="primary"
                        :waiting="saving()"
                        :disabled="!savable"
                        class="ml-2"
                        @click.prevent="save"
                    >
                        <span v-if="remote_id === '__new__'">{{ $root.translate("Create") }}</span>
                        <span v-else>{{ $root.translate("Link") }}</span>
                    </default-button>
                </div>
            </template>
        </modal-dialog>
    </div>
</template>

<script>
import { mapGetters } from "vuex"

import handles_saving from "@/nibnut/mixins/HandlesSaving"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "ClientServiceLinkDialog",
    mixins: [handles_saving],
    components: {
        ModalDialog,
        FormInput,
        DefaultButton,
        OpenIcon
    },
    mounted () {
        this.reset()
    },
    watch: {
        show: "reset"
    },
    methods: {
        reset () {
            if(this.service && this.service.uses_custom_urls) this.query = ""
            else this.query = this.show ? this.client.abbreviation || this.client.name : ""
            if(this.show) this.search()
        },
        search () {
            if(!this.query) {
                this.companies = []
                this.remote_id = null
                return
            }
            this.waiting = true
            this.$store.dispatch("AUTOSUGGEST", {
                entity: "client_service",
                context: this.service.slug,
                data: { query: this.query },
                passthru: true
            }).then(companies => {
                this.companies = companies || []
                this.companies.forEach(company => {
                    if(company.name.toLowerCase() === this.query.toLowerCase()) this.remote_id = company.id
                })
            }).catch(error => {
                this.$error(error.message)
            }).then(() => {
                this.waiting = false
            })
        },
        close () {
            this.shown = false
        },
        save () {
            // ****** MULTIPLE CLIENT_SERVICES: allow multiple selection (button as toggles) ; loop on selection: re-use any existing record, create missing, delete/inactivate unused
            const client_service = this.entity_records("client_service").find(client_service => {
                return (client_service.client_id === this.client.id) && (client_service.service_id === this.service.id)
            }) || { id: 0 }
            const data = { remote_id: this.remote_id }
            if(this.service.uses_custom_urls) data.remote_name = this.query
            if(!client_service.id) {
                data.client_id = this.client.id
                data.service_id = this.service.id
            }
            this.save_data_for_record_id("client_service", client_service.id, data)
        },
        saved_data (record, is_new, data) {
            this.close()
        }
    },
    computed: {
        ...mapGetters(["entity_records"]),
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        service_entity () {
            if(this.service) {
                if(this.service.slug === "bit-defender") return this.translate("company")
            }
            return this.translate("record")
        },
        service_remote_name_label () {
            if(this.service) {
                if(this.service.slug === "hexnode") return this.translate("Portal")
                if(this.service.slug === "jamf") return this.translate("URL")
            }
            return this.translate("remote name")
        },
        service_remote_id_label () {
            if(this.service) {
                if(this.service.slug === "jamf") return this.translate("Username:password")
            }
            return this.translate("API key")
        },
        savable () {
            if(this.service && this.service.uses_custom_urls) return !!this.query && !!this.remote_id
            return !!this.remote_id
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        client: {
            type: Object,
            required: true
        },
        service: {
            type: Object,
            required: true
        },
        canCreate: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            query: "",
            remote_id: null,
            companies: [],
            waiting: false
        }
    }
}
</script>

<style lang="scss">
</style>
