<template>
    <modal-dialog
        id="upsell-editor"
        :show.sync="shown"
        :dismissable="true"
    >
        <template v-slot:title>
            <span class="h5">{{ translate("Upsell {name}", { name: service.name }) }}</span>
        </template>

        <form-toggle-input
            v-if="toggable"
            id="upsell_message_toggle"
            name="upsell"
            type="checkbox"
            :value="active"
            :required="false"
            @input="toggle_active"
        >
            {{ translate('Upsell') }}
        </form-toggle-input>
        <form-toggle-input
            v-if="toggable && active"
            id="upsell-editor-customizing"
            name="customizing"
            type="checkbox"
            :value="customizing"
            :required="false"
            size="sm"
            @input="toggle_customization"
        >
            {{ translate('Custom Upsell Text') }}
        </form-toggle-input>
        <form-editor
            v-if="active"
            id="upsell_message"
            :data-version="dataVersion"
            name="upsell_message"
            :value="value || defaultValue"
            :placeholder="!!defaultValue ? defaultValue : ''"
            :editable="customizing"
            size="lg"
            :tokens="tokens_for_service(service.slug)"
            :required="false"
            @input="save"
        >
        </form-editor>
    </modal-dialog>
</template>

<script>
import handles_dynamic_tokens from "@/custom/mixins/HandlesDynamicTokens"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import FormEditor from "@/nibnut/components/Inputs/FormEditor"
import FormToggleInput from "@/nibnut/components/Inputs/FormToggleInput"

/*
            <template v-slot:read_only>
                <div
                    v-html="defaultValue"
                    class="upsell-editor-default-value"
                ></div>
            </template>
*/
export default {
    name: "UpsellEditorDialog",
    mixins: [handles_dynamic_tokens],
    components: {
        ModalDialog,
        FormEditor,
        FormToggleInput
    },
    mounted () {
        this.reset()
    },
    watch: {
        show: "reset",
        dataVersion: "reset"
    },
    methods: {
        reset () {
            this.customizing = (this.active && !!this.value)
        },
        save (value, field) {
            this.$emit("input", value, field)
        },
        toggle_active (active) {
            if((this.active !== active) && !active) this.customizing = false
            this.save(active, "upsell")
        },
        toggle_customization (customizing) {
            if(this.customizing !== customizing) {
                this.customizing = customizing
                if(!customizing) this.save(null, "upsell_message")
            }
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        }
    },
    props: {
        service: {
            type: Object,
            required: true
        },
        dataVersion: {
            type: Number,
            required: true
        },
        active: {
            type: Boolean,
            default: true
        },
        value: {
            type: String,
            default: ""
        },
        defaultValue: {
            type: String,
            default: ""
        },
        show: {
            type: Boolean,
            default: false
        },
        toggable: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            customizing: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

#upsell-editor {
    .upsell-editor-default-value {
        border: $border-width solid $border-color-dark;
        border-radius: $border-radius;
        color: $body-font-color;
        padding: $control-padding-y $control-padding-x;
    }
}
</style>
