<template>
    <div>
        <modal-dialog
            id="contact-editor-dialog"
            :show.sync="shown"
            :dismissable="true"
        >
            <template v-slot:title>
                <span v-if="!!edited_record && !edited_record.id" class="h5">{{ $root.translate('New Contact') }}</span>
                <span v-else-if="!!edited_record" class="h5">{{ string_concat(" ", edited_record.first_name, edited_record.last_name) }}</span>
            </template>

            <div
                v-if="waiting"
                class="text-center py-8"
            >
                <loader size="sm" />
            </div>
            <div v-else-if="!!edited_record">
                <div class="columns">
                    <div class="column flex-static">
                        <form-dropdown
                            id="title"
                            name="title"
                            v-model="edited_record.title"
                            :options="titles"
                            :empty-label="$root.translate('None')"
                            :required="false"
                            :disabled="true"
                        >
                            <template v-slot:label>{{ $root.translate("Title") }}</template>
                        </form-dropdown>
                    </div>
                    <div class="column">
                        <form-input
                            id="first_name"
                            name="first_name"
                            v-model="edited_record.first_name"
                            :required="true"
                            :disabled="true"
                        >
                            <template v-slot:label>{{ $root.translate("First Name") }}</template>
                        </form-input>
                    </div>
                    <div class="column">
                        <form-input
                            id="last_name"
                            name="last_name"
                            v-model="edited_record.last_name"
                            :required="true"
                            :disabled="true"
                        >
                            <template v-slot:label>{{ $root.translate("Last Name") }}</template>
                        </form-input>
                    </div>
                </div>

                <div class="columns">
                    <div class="column col-6 col-sm-12">
                        <form-input
                            id="email"
                            name="email"
                            v-model="edited_record.email"
                            :required="false"
                            :disabled="true"
                        >
                            <template v-slot:label>{{ $root.translate("Email") }}</template>
                        </form-input>
                    </div>
                    <div class="column col-6 col-sm-12">
                        <form-input
                            id="position"
                            name="position"
                            v-model="edited_record.position"
                            :required="false"
                            :disabled="true"
                        >
                            <template v-slot:label>{{ $root.translate("Position") }}</template>
                        </form-input>
                    </div>
                    <div class="column col-6 col-sm-12">
                        <form-input
                            id="mobile"
                            name="mobile"
                            v-model="edited_record.mobile"
                            :required="false"
                            :disabled="true"
                        >
                            <template v-slot:label>{{ $root.translate("Mobile") }}</template>
                        </form-input>
                    </div>
                    <div class="column col-6 col-sm-12">
                        <form-dropdown
                            id="status"
                            name="status"
                            v-model="edited_record.status"
                            :options="statuses"
                            label-field="title"
                            :required="false"
                            :disabled="true"
                        >
                            <template v-slot:label>{{ $root.translate("Status") }}</template>
                        </form-dropdown>
                    </div>
                </div>

                <form-textbox
                    id="comments"
                    name="comments"
                    v-model="edited_record.comments"
                    :required="false"
                    :disabled="true"
                >
                    <template v-slot:label>{{ $root.translate("Comments") }}</template>
                </form-textbox>

                <!--
                <div v-if="!!edited_record && !!edited_record.id" class="text-center mt-8">
                    <default-button
                        flavor="link"
                        color="error"
                        size="sm"
                        @click.prevent="confirm_record_delete"
                    >
                        {{ $root.translate("Delete Contact") }}
                    </default-button>
                </div>
                //-->
            </div>

            <template v-slot:footer>
                <div class="text-center">
                    <default-button
                        :disabled="creating_record"
                        class="mr-2"
                        @click.prevent="close"
                    >
                        {{ $root.translate("Cancel") }}
                    </default-button>
                    <default-button
                        color="primary"
                        :waiting="creating_record"
                        class="ml-2"
                        @click.prevent="record_save"
                    >
                        <span v-if="!!edited_record && !edited_record.id">{{ $root.translate("Create") }}</span>
                        <span v-else>{{ $root.translate("Save") }}</span>
                    </default-button>
                </div>
            </template>
        </modal-dialog>

        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import is_record_editor from "@/nibnut/mixins/IsRecordEditor"
import string_utilities from "@/nibnut/mixins/StringUtilities"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormDropdown from "@/nibnut/components/Inputs/FormDropdown"
import FormTextbox from "@/nibnut/components/Inputs/FormTextbox"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import Loader from "@/custom/components/Loader"

export default {
    name: "ContactEditorDialog",
    mixins: [is_record_editor, string_utilities],
    components: {
        ModalDialog,
        FormInput,
        FormDropdown,
        FormTextbox,
        DefaultButton,
        Loader
    },
    watch: {
        show: "reload",
        contactId: "reload"
    },
    methods: {
        grab_record_id () {
            this.record_id = this.contactId
        },
        post_load () {
            if(this.shell_record && !this.shell_record.client_id) {
                this.shell_record.client_id = this.client.id
                this.shell_record.company = this.client.remote_id
            }
        },
        close () {
            this.record_id = 0
            this.creating_record = false
            this.shown = false
        },
        save (value, field) {
            if(this.edited_record) {
                if(this.edited_record[field] !== value) this.edited_record[field] = value
                // Accelo sync is too long, so we save all or nothing
            }
            return Promise.resolve()
        },
        record_save () {
            const edited_record = this.edited_record
            if(!edited_record.client_id) edited_record.client_id = this.client.id
            const is_new = !edited_record.id
            if(is_new) this.creating_record = true
            return this.save_data_for_record_id(this.entity, edited_record.id || 0, edited_record)
        },
        saved_data () {
            this.$emit("created")
            this.close()
        },
        confirm_record_delete () {
            this.confirm(
                {
                    type: "error",
                    title: this.$root.translate("Delete {name}", { name: this.string_concat(" ", this.edited_record.first_name, this.edited_record.last_name) }),
                    message: this.$root.translate("Do you really want to delete this contact? It will also be deleted in Accelo and there is no undo..."),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete")
                },
                "delete-record"
            )
        },
        confirmed () {
            if(this.confirming === "delete-record") this.record_delete()
            else this.done_confirming()
        },
        record_deleted () {
            this.shown = false
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        can_reload () {
            return this.profile_id && this.show && (this.contactId !== this.record_id)
        },
        cleanup_before_reload () {
            return false
        },
        reload_query () {
            return { relation_ids: this.relation_ids, client_id: this.client.id }
        },
        titles () {
            return Object.values(this.constants("titles"))
        },
        statuses () {
            return Object.values(this.constants("contact_statuses"))
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        client: {
            type: Object,
            required: true
        },
        contactId: {
            validator: prop => (prop === null) || (typeof prop === "string"),
            default: null
        }
    },
    data () {
        return {
            entity: "contact",
            entityName: "contact:::contacts",
            waiting: false
        }
    }
}
</script>

<style lang="scss">
</style>
