<template>
    <div
        v-if="field_definition"
    >
        <form-input
            v-if="field_definition.type.match(/^(text|int|float|decimal|currency)$/i)"
            :id="field_definition.identifier"
            :name="field_definition.identifier"
            :value="value"
            :type="text_field_type"
            :size="size"
            :autocomplete="(text_field_type === 'password') ? 'new-password' : ''"
            :required="!!field_definition.required"
            :disabled="disabled || saving"
            :editable="editable"
            :saving="saving"
            :error="error"
            v-on="$listeners"
        >
            <template v-slot:label>{{ field_definition.name }}</template>
        </form-input>
        <form-textbox
            v-else-if="field_definition.type === 'textbox'"
            :id="field_definition.identifier"
            :name="field_definition.identifier"
            :value="value"
            :size="size"
            :required="!!field_definition.required"
            :disabled="disabled || saving"
            :editable="editable"
            :saving="saving"
            :error="error"
            v-on="$listeners"
        >
            <template v-slot:label>{{ field_definition.name }}</template>
        </form-textbox>
        <form-select
            v-else-if="(field_definition.type === 'flexible_select')"
            :id="field_definition.identifier"
            :name="field_definition.identifier"
            :value="value"
            :data-source="field_options"
            :size="size"
            :ad-hoc="true"
            :show-all="field_options.length <= 10"
            :required="!!field_definition.required"
            :disabled="disabled || saving"
            :editable="editable"
            :saving="saving"
            :error="error"
            v-on="$listeners"
        >
            <template v-slot:label>{{ field_definition.name }}</template>
        </form-select>
        <form-select
            v-else-if="!!field_definition.type.match(/(fixed_)?select/) && (!field_definition.options || (field_definition.options.length !== 2))"
            :id="field_definition.identifier"
            :name="field_definition.identifier"
            :value="value"
            :data-source="field_options"
            :size="size"
            :required="!!field_definition.required"
            :disabled="disabled || saving"
            :editable="editable"
            :saving="saving"
            :error="error"
            v-on="$listeners"
        >
            <template v-slot:label>{{ field_definition.name }}</template>
        </form-select>
        <form-group
            v-else-if="!!field_definition.type.match(/(fixed_)?select/) && !!field_definition.options && (field_definition.options.length === 2)"
            :id="field_definition.identifier"
            :name="field_definition.identifier"
            :value="value"
            :required="!!field_definition.required"
            :editable="true || editable"
            :error="error"
        >
            <template v-slot:read_only>
                <slot name="read_only">
                    <label
                        class="form-label"
                        :for="field_definition.identifier"
                    >
                        {{ field_definition.name }}
                    </label>
                    <label
                        :class="{ 'text-gray': !display_value }"
                        class="form-label disabled-field"
                    >
                        {{ display_value || translate("(Not Set)") }}
                    </label>
                </slot>
            </template>
            <template v-slot:label>{{ field_definition.name }}</template>
            <div class="columns">
                <form-toggle-input
                    v-for="option in field_definition.options"
                    :key="option"
                    :id="`${field_definition.identifier}-${option}`"
                    :name="field_definition.identifier"
                    :value="value"
                    :true-value="option"
                    :required="!!field_definition.required"
                    :disabled="disabled || saving"
                    :editable="editable"
                    :saving="saving"
                    :error="error"
                    class="column flex-static"
                    v-on="$listeners"
                >
                    {{ option }}
                </form-toggle-input>
            </div>
        </form-group>
        <form-select
            v-else-if="(field_definition.type === 'lookup')"
            :id="field_definition.identifier"
            :name="field_definition.identifier"
            :value="value"
            data-source="service_repair_order"
            :data-source-additional-data="{ table: field_definition.lookup_type, fields: ['id', 'name'] }"
            :size="size"
            :show-all="false"
            :required="!!field_definition.required"
            :disabled="disabled || saving"
            :editable="editable"
            :saving="saving"
            :error="error"
            v-on="$listeners"
        >
            <template v-slot:label>{{ field_definition.name }}</template>
        </form-select>
        <form-tag-input
            v-else-if="(field_definition.type === 'multi_select')"
            :id="field_definition.identifier"
            :name="field_definition.identifier"
            :value="value || []"
            :data-source="field_options"
            :required="!!field_definition.required"
            :disabled="disabled || saving"
            :editable="editable"
            :saving="saving"
            :error="error"
            v-on="$listeners"
        >
            <template v-slot:label>{{ field_definition.name }}</template>
        </form-tag-input>
        <div v-else>{{ field_definition }}</div>
        <!-- "date", ("date_time"), "certify"?, "hyperlink?", "structure?", or "contributor?" //-->
    </div>
</template>

<script>
import addl_misc_utilities from "@/custom/mixins/AddlMiscUtilities"

import FormGroup from "@/nibnut/components/Inputs/FormGroup"
import FormInput from "@/nibnut/components/Inputs/FormInput"
import FormSelect from "@/nibnut/components/Inputs/FormSelect"
import FormToggleInput from "@/nibnut/components/Inputs/FormToggleInput"
import FormTagInput from "@/nibnut/components/Inputs/FormTagInput"

export default {
    name: "FormAcceloCustomField",
    mixins: [addl_misc_utilities],
    components: {
        FormGroup,
        FormInput,
        FormSelect,
        FormToggleInput,
        FormTagInput
    },
    methods: {
    },
    computed: {
        field_definition () {
            const services = this.setting("services")
            if(!!services && !!services.accelo && !!services.accelo.fields) return services.accelo.fields[this.field]
            return null
        },
        text_field_type () {
            const field_definition = this.field_definition
            if(field_definition.type && field_definition.type.match(/^(float|decimal|currency)$/i)) return "float"
            if(field_definition.type && field_definition.type.match(/^(int)$/i)) return "number"
            if(field_definition.name && field_definition.name.match(/pass/i)) return "password"
            return "text"
        },
        field_options () {
            const field_definition = this.field_definition
            if(field_definition.options) return field_definition.options.map(option => { return { id: option, name: option } })
            return []
        },
        display_value () {
            return this.value
        }
    },
    props: {
        field: {
            type: String,
            validator: prop => !!prop,
            required: true
        },
        value: {
            required: true
        },
        size: {
            type: String,
            validator: prop => !!prop && prop.match(/^(sm|md|lg)$/i),
            default: "md"
        },
        disabled: { // disable input field
            type: Boolean,
            default: false
        },
        editable: { // read-only
            type: Boolean,
            default: true
        },
        saving: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ""
        }
    }
}
</script>
