var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{directives:[{name:"touch",rawName:"v-touch:swipe",value:(_vm.swiped),expression:"swiped",arg:"swipe"}],staticClass:"nibnut-sidenav",class:{ active: _vm.navigating }},[_c('ul',{staticClass:"nav"},[_c('router-link',{attrs:{"to":{ name: 'home' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('li',{staticClass:"nav-item",class:{ 'active': (_vm.$route.name === 'home') || (_vm.$route.name === 'ui-catalog') }},[_c('base-link',{attrs:{"href":href,"title":_vm.$root.translate('Home')},nativeOn:{"click":function($event){navigate($event); _vm.$emit('navigate', false)}}},[_c('open-icon',{attrs:{"glyph":"home","size":"1x"}}),_c('span',[_vm._v(_vm._s(_vm.$root.translate("Home")))])],1)],1)]}}])}),_c('router-link',{attrs:{"to":{ name: 'service_repair_order.list' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{staticClass:"nav-item",class:{ 'active': isActive }},[_c('base-link',{attrs:{"href":href,"title":_vm.$root.translate('SRO')},nativeOn:{"click":function($event){navigate($event); _vm.$emit('navigate', false)}}},[_c('open-icon',{attrs:{"glyph":"tools","size":"1x"}}),_c('span',[_vm._v(_vm._s(_vm.$root.translate("SRO")))])],1)],1)]}}])}),_c('router-link',{attrs:{"to":{ name: 'client.list' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{staticClass:"nav-item",class:{ 'active': isActive }},[_c('base-link',{attrs:{"href":href,"title":_vm.$root.translate('Clients')},nativeOn:{"click":function($event){navigate($event); _vm.$emit('navigate', false)}}},[_c('open-icon',{attrs:{"glyph":"headset","size":"1x"}}),_c('span',[_vm._v(_vm._s(_vm.$root.translate("Clients")))])],1)],1)]}}])}),_c('router-link',{attrs:{"to":{ name: 'reports' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{staticClass:"nav-item",class:{ 'active': isActive }},[_c('base-link',{attrs:{"href":href,"title":_vm.$root.translate('Reports')},nativeOn:{"click":function($event){navigate($event); _vm.$emit('navigate', false)}}},[_c('open-icon',{attrs:{"glyph":"file-invoice-dollar","size":"1x"}}),_c('span',[_vm._v(_vm._s(_vm.$root.translate("Reports")))])],1)],1)]}}])}),(_vm.is_at_least_administrator)?_c('router-link',{attrs:{"to":{ name: 'user.list' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{staticClass:"nav-item",class:{ 'active': isActive }},[_c('base-link',{attrs:{"href":href,"title":_vm.$root.translate('Users')},nativeOn:{"click":function($event){navigate($event); _vm.$emit('navigate', false)}}},[_c('open-icon',{attrs:{"glyph":"users","size":"1x"}}),_c('span',[_vm._v(_vm._s(_vm.$root.translate("Users")))])],1)],1)]}}],null,false,3483937787)}):_vm._e(),(_vm.is_at_least_administrator)?_c('router-link',{attrs:{"to":{ name: 'settings' },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{staticClass:"nav-item",class:{ 'active': isActive }},[_c('base-link',{attrs:{"href":href,"title":_vm.$root.translate('Settings')},nativeOn:{"click":function($event){navigate($event); _vm.$emit('navigate', false)}}},[_c('open-icon',{attrs:{"glyph":"cog","size":"1x"}}),_c('span',[_vm._v(_vm._s(_vm.$root.translate("Settings")))])],1)],1)]}}],null,false,1488017330)}):_vm._e(),(_vm.installable)?_c('li',{staticClass:"nav-item"},[_c('base-link',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('install')}}},[_c('open-icon',{attrs:{"glyph":"arrow-circle-down","size":"1x"}}),_c('span',[_vm._v(_vm._s(_vm.$root.translate("Install")))])],1)],1):_vm._e()],1),_c('div',{staticClass:"nibnut-sidenav-footer"},[(!_vm.navigating)?_c('div',[_vm._v(" © "+_vm._s(_vm.current_year)),_c('br'),_vm._v(" v."+_vm._s(_vm.app_version)+" ")]):_c('div',[_vm._v(" © "+_vm._s(_vm.current_year)+" "+_vm._s(_vm.setting("customer_name"))),_c('br'),_vm._v(" "+_vm._s(_vm.$root.translate("App sprouted from a"))+" "),_c('a',{attrs:{"href":"https://nibnut.com"}},[_c('base-image',{attrs:{"src":_vm.nibnut_logo,"alt":"nibnut"}})],1),_vm._v(" • v."+_vm._s(_vm.app_version)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }