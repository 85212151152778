<template>
    <div v-if="state.found" class="card billables">
        <div class="card-header">
            <h4>
                <open-icon glyph="file-invoice-dollar" /> {{ $root.translate("Billables") }}
            </h4>
        </div>
        <div class="card-body">
            <div
                v-if="!loading && !!state.found"
                class="nibnut-data-table"
            >
                <div
                    v-for="(client, client_index) in filtered_rows"
                    :key="client.id"
                    class="client-table"
                >
                    <table
                        class="table table-scroll navigation"
                    >
                        <thead>
                            <tr>
                                <th class="column-identifier">
                                    <span class="d-invisible">{{ client.name }}</span>
                                    <default-button
                                        v-if="page < page_count"
                                        color="primary"
                                        size="sm"
                                        class="nav-prev"
                                        @click.prevent="scroll_table(client_index, 1)"
                                    >
                                        <open-icon glyph="caret-left" />
                                    </default-button>
                                </th>
                                <th
                                    v-for="(date, index) in dates"
                                    :key="date"
                                    class="column-data"
                                >
                                    <span class="d-invisible">&nbsp;</span>
                                    <default-button
                                        v-if="!index && !!page"
                                        color="primary"
                                        size="sm"
                                        class="nav-next"
                                        @click.prevent="scroll_table(client_index, -1)"
                                    >
                                        <open-icon glyph="caret-right" />
                                    </default-button>
                                </th>
                            </tr>
                        </thead>
                    </table>
                    <table
                        ref="table"
                        class="table table-scroll table-striped data"
                    >
                        <thead>
                            <tr>
                                <th class="column-identifier">
                                    {{ client.name }}
                                </th>
                                <th
                                    v-for="date in dates"
                                    :key="date"
                                    class="column-data"
                                >
                                    {{ date | nibnut.date("LLL-d") }}
                                </th>
                            </tr>
                        </thead>
                        <tbody
                            v-for="product in client_products(client)"
                            :key="product.id"
                        >
                            <tr class="sub-header">
                                <th class="column-identifier">{{ product.name }}</th>
                                <td
                                    v-for="date in dates"
                                    :key="date"
                                    class="column-data"
                                >
                                    {{ translate("Qty") }}
                                </td>
                            </tr>
                            <tr
                                v-for="billable in product_billables(product)"
                                :key="billable.id"
                            >
                                <!--
                                <td class="checkbox">
                                    <form-toggle-input
                                        id="billed"
                                        name="billed"
                                        type="checkbox"
                                        :title="translate('Mark as Billed')"
                                        v-model="billable.billed"
                                        :required="false"
                                    />
                                </td>
                                //-->
                                <th class="column-identifier">
                                    {{ billable.sku }}
                                </th>
                                <td
                                    v-for="(date, index) in dates"
                                    :key="date"
                                    class="column-data"
                                >
                                    <delta-display
                                        :value="billable.counts[index].delta"
                                        :color-override="(index !== page)"
                                    >
                                        <template v-slot:default="{ absoluteValue }">
                                            <span v-if="!absoluteValue">&mdash;</span>
                                            <span v-else>
                                                {{ translate("1 seat:::{n} seats", { n: nibnut_filter("nibnut.number", [absoluteValue, "0,0"]) }, absoluteValue) }}
                                            </span>
                                        </template>
                                        <template v-slot:info>
                                            <div
                                                class="text-small text-gray"
                                            >
                                                {{ translate("1 seat:::{n} seats", { n: nibnut_filter("nibnut.number", [billable.counts[index].count, "0,0"]) }, billable.counts[index].count) }}
                                            </div>
                                        </template>
                                    </delta-display>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div
                v-else-if="!loading"
                class="empty"
            >
                <p class="empty-title h5">
                    {{ translate("No billables found during that period") }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import {
    startOfMonth,
    subMonths,
    subDays
} from "date-fns"

import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"

import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"
import DeltaDisplay from "@/custom/components/DeltaDisplay"

export default {
    name: "Billables",
    mixins: [is_remote_data_table_source],
    components: {
        DeltaDisplay,
        DefaultButton,
        OpenIcon
    },
    mounted () {
        if(!this.state.filter) {
            this.filter("unpaid-billables", this.filters.last.range)
        }
    },
    methods: {
        post_load () {
            setTimeout(() => {
                this.filtered_rows.forEach((noop, index) => {
                    this.scroll_table(index, 0)
                })
            }, 10)
        },
        client_products (client) { // ****
            return [
                { id: 1, client_id: client.id, name: "Sherweb" },
                { id: 2, client_id: client.id, name: "BitDefender" }
            ]
        },
        product_billables (product) { // ****
            return [
                { id: 1, product_id: product.id, sku: "SKU 1", date: "2022-05-02", counts: [{ delta: 1, count: 3 }, { delta: 1, count: 2 }, { delta: 0, count: 1 }, { delta: 1, count: 1 }, { delta: 0, count: 0 }], delta: 1, total: 13, unit_price: 12, billed: false },
                { id: 2, product_id: product.id, sku: "SKU 2", date: "2022-05-05", counts: [{ delta: -2, count: 3 }, { delta: 2, count: 5 }, { delta: 0, count: 3 }, { delta: 0, count: 3 }, { delta: 3, count: 3 }], delta: -2, total: 10, unit_price: 12, billed: false }
            ]
        },
        client_total (client) {
            let total = 0
            this.client_products(client).forEach(product => {
                total += this.product_total(product)
            })
            return total
        },
        product_total (product) {
            let total = 0
            this.product_billables(product).forEach(billable => {
                total += (billable.delta * billable.unit_price)
            })
            return total
        },
        product_count (product) {
            let total = 0
            this.product_billables(product).forEach(billable => {
                total += billable.delta
            })
            return total
        },
        scroll_table (client_index, delta) {
            const cells = this.$refs.table[client_index].querySelectorAll("thead > tr > th")
            const cell = cells[cells.length - 1]
            const width = cell.clientWidth

            this.page_count = Math.ceil((this.$refs.table[client_index].scrollWidth - this.$refs.table[client_index].clientWidth) / width)
            this.page += delta
            this.page = Math.min(this.page, this.page_count)
            this.page = Math.max(this.page, 0)

            let left = (this.page * width)
            left = Math.max(left, 0)
            this.$refs.table[client_index].scrollTo({ left, top: 0, behavior: "smooth" })
        }
    },
    computed: {
        can_refresh () {
            return this.profile_id && !!this.state.filter
        },
        fields () {
            return ["fieldset::dashboard"]
        },
        filtered_rows () {
            let rows = this.rows.slice()

            if(!this.state.archived && !this.filtered_by_selection) rows = rows.filter(record => !record.deleted_at)

            rows = this.sort_rows(rows)

            return this.page_rows(rows)
        },
        today () {
            return new Date()
        },
        dates () {
            return [
                "2022-01-31",
                "2022-02-28",
                "2022-03-31",
                "2022-04-30",
                "2022-05-31"
            ].reverse()
        },
        filters () {
            const today = this.today
            return {
                last: { title: this.translate("Last Month"), range: { to: subDays(startOfMonth(today), 1), from: subMonths(startOfMonth(today), 1) } },
                current: { title: this.translate("This Month"), range: { to: today, from: startOfMonth(today) } },
                custom: { title: this.translate("Custom"), range: { to: today, from: startOfMonth(today) } }
            }
        },
        presets () {
            const filters = this.filters
            return [
                filters.last,
                filters.current
            ]
        }
    },
    data () {
        return {
            entity: "client",
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: "unpaid-billables",
                filter: null, // start with last month
                archived: false,
                search: "",
                total: 0,
                found: 0
            },
            picking: false,
            page: 0,
            page_count: 1
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/app";

.card.billables {
    .btn-group {
        @media (min-width: $size-sm + 1) {
            float: right;
        }
        @media (max-width: $size-sm) {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .client-table {
        position: relative;
        & > table.table.navigation,
        & > table.table.data {
            width: 100%;
            overflow: hidden;

            th {
                position: static;
                top: auto;
                border-bottom: 0;

                & > .btn {
                    position: absolute;
                    top: 50%;
                    transform: translate(0, -50%);
                    border-color: transparent;

                    &.nav-prev {
                        left: 100%;
                    }
                    &.nav-next {
                        right: 0;
                        top: 40%;
                    }
                }
            }
            tr {
                th.column-identifier {
                    position: sticky;
                    top: 0;
                    left: 0;
                }
            }
            th.column-data,
            td.column-data {
                min-width: calc(25vw - 4rem);
                text-align: center;
            }
        }
        & > table.table.navigation {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 500;

            &, & > thead, & > thead th {
                background-color: transparent;
            }
        }
        & > table.table.data {
            tr {
                th.column-identifier {
                    background-color: $light-color;
                }
                &:nth-of-type(odd) {
                    th.column-identifier {
                        background-color: $bg-color;
                    }
                }
            }
            th.count,
            td.count,
            th.amount,
            td.amount {
                text-align: right;
                white-space: nowrap;
            }
            th.count,
            td.count {
                font-size: 0.8rem;
            }

            & > thead {
                th,
                th.column-identifier,
                th.column-identifier:nth-of-type(odd) {
                    background-color: $brand-color;
                    color: $light-color;
                }
            }
            & > tbody {
                & > tr > th {
                    border-bottom: $border-width solid $border-color;
                }
                & > tr.sub-header {
                    td,
                    th.column-identifier,
                    th.column-identifier:nth-of-type(odd) {
                        background-color: $brand-color-light;
                        font-weight: bold;
                    }
                }
                & > tr:not(.sub-header) td {
                    font-size: 0.8rem;
                    padding-top: 0;
                    padding-bottom: 0;
                }
            }
        }
    }
}
</style>
