<template>
    <div
        :class="{ 'text-success': !colorOverride && (value > 0), 'text-error': !colorOverride && (value < 0), 'text-gray': !colorOverride && (value == 0) }"
    >
        <span
            :class="{ 'd-invisible': (value == 0) }"
        >
            <span v-if="(value > 0)">+</span>
            <span v-else-if="(value < 0)">-</span>
            <span v-else>&nbsp;</span>
        </span>
        <slot :absolute-value="absolute_value">
            {{ absolute_value | nibnut.number("0,0") }}
        </slot>
        <slot name="info"></slot>
    </div>
</template>

<script>
export default {
    name: "DeltaDisplay",
    computed: {
        absolute_value () {
            return Math.abs(this.value)
        }
    },
    props: {
        value: {
            type: Number,
            default: 0
        },
        colorOverride: {
            type: Boolean,
            default: false
        }
    }
}
</script>
