<template>
    <modal-dialog
        id="client-service-report-dialog"
        :show.sync="shown"
        :dismissable="true"
    >
        <template v-slot:title>
            <span v-if="!!report.id" class="h5">{{ $root.translate("{service} for {name}", { service: service.name, name: client.name }) }}</span>
        </template>

        <div
            v-if="loading"
            class="text-center py-8"
        >
            <loader size="sm" />
        </div>
        <div v-else-if="!!report.id">
            <component
                :is="report_component"
                :records="filtered_rows"
            />
        </div>
    </modal-dialog>
</template>

<script>
import orderBy from "lodash/orderBy"

import is_remote_data_table_source from "@/nibnut/mixins/IsRemoteDataTableSource"

import ModalDialog from "@/nibnut/components/ModalDialog/ModalDialog"
import Loader from "@/custom/components/Loader"

export default {
    name: "ClientServiceReportDialog",
    mixins: [is_remote_data_table_source],
    components: {
        ModalDialog,
        Loader,
        BitdefenderReport: () => import("./BitdefenderReport"),
        ZendeskReport: () => import("./ZendeskReport"),
        WatchmanReport: () => import("./WatchmanReport"),
        CrashplanReport: () => import("./CrashplanReport"),
        DnsFilterReport: () => import("./DnsFilterReport"),
        ZeroSpamReport: () => import("./ZeroSpamReport")
    },
    watch: {
        show: "reset"
    },
    methods: {
        pre_load () {
            switch (this.service.slug) {
            case "bit-defender":
                this.entity = "bitdefender_endpoint"
                this.relation_ids = ["bitdefender_threat"]
                break
            case "zen-desk":
                this.entity = "zendesk_ticket"
                break
            case "watchman":
                this.entity = "watchman_computer"
                break
            case "background-backup":
                this.entity = "crashplan_computer"
                break
            case "dns-filter":
                this.entity = "dns_filter_log"
                this.relation_ids = ["dns_filter_domain"]
                break
            case "zero-spam":
                this.entity = "zerospam_email_stat"
                break
            }
            this.set_state_values({
                client_id: this.client.id,
                service_id: this.service.id
            })
        }
    },
    computed: {
        shown: {
            get () {
                return this.show
            },
            set (show) {
                this.$emit("update:show", show)
            }
        },
        can_refresh () {
            return this.profile_id && this.show
        },
        state_identifier () {
            return "client-service-report-dialog"
        },
        fields () {
            return ["fieldset::list", "ns::bitdefender_threat;fieldset::list", "ns::dns_filter_domain;fieldset::list"]
        },
        report () {
            if(!this.rows.length) return {}
            return orderBy(this.rows, "generated_at", "desc")[0]
        },
        report_component () {
            if(this.service.slug) {
                if(this.service.slug === "bit-defender") return "BitdefenderReport"
                if(this.service.slug === "zen-desk") return "ZendeskReport"
                if(this.service.slug === "watchman") return "WatchmanReport"
                if(this.service.slug === "background-backup") return "CrashplanReport"
                if(this.service.slug === "dns-filter") return "DnsFilterReport"
                if(this.service.slug === "zero-spam") return "ZeroSpamReport"
                if(this.service.slug === "uptime-robot") return "UptimeRobotReport"
            }
            return null
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        client: {
            type: Object,
            required: true
        },
        service: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            entity: "client_service_report",
            default_state: {
                per_page: 0,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                client_id: 0,
                service_id: 0
            }
        }
    }
}
</script>
