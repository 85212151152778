
import { mapGetters } from "vuex"
import orderBy from "lodash/orderBy"

import misc_utilities from "@/nibnut/mixins/MiscUtilities"

const addl_misc_utilities = {
    mixins: [misc_utilities],
    methods: {
        load_vocabularies (vocabularies) {
            if(this.profile_id) {
                return this.$store.dispatch(
                    "FETCH_RECORDS",
                    {
                        entity: "term",
                        query: {
                            vocabularies
                        }
                    }
                )
            }
            return Promise.resolve()
        },
        vocabulary (identifier) {
            if(!!this.app_context && !!this.app_context.constants && !!this.app_context.constants.vocabularies) {
                const vocabulary_id = this.app_context.constants.vocabularies[identifier].id
                return orderBy(this.entity_records("term").filter((term, id) => {
                    return !term.parent_id && (term.vocabulary === vocabulary_id)
                }), ["sort_order", "name"], "asc")
            }
            return []
        },
        term_by_id (id) {
            return this.entity_record("term", id)
        }
    },
    computed: {
        ...mapGetters(["entity_records", "entity_record"])
    }
}

export default addl_misc_utilities
