<template>
    <div
        :class="{ [`la-${size}`]: true, [`la-${color}`]: true }"
        class="ball-scale-multiple loader"
    >
        <div
            v-for="index in 3"
            :key="index"
        ></div>
    </div>
</template>

<script type="text/javascript">
/*
https://codepen.io/kjbrum/pen/BraCg
https://connoratherton.com/loaders
http://anastawfeek.github.io/ArtWorx-xLoader/
https://github.danielcardoso.net/load-awesome/animations.html
*/
export default {
    name: "Loader",
    props: {
        size: {
            type: String,
            validator: prop => !prop || !!prop.match(/^(sm|md|2x|3x)$/),
            default: "md"
        },
        color: {
            type: String,
            validator: prop => !prop || !!prop.match(/^(light|gray|dark)$/),
            default: "dark"
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.ball-scale-multiple {
    display: block;
    font-size: 0;
    color: $light-color;
    width: 32px;
    height: 32px;

    &,
    & > div {
        position: relative;
        box-sizing: border-box;
    }
    & > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 1.6rem;
        height: 1.6rem;
        border-radius: 100%;
        opacity: 0;
        animation: ball-scale-multiple 1s 0s linear infinite;
        display: inline-block;
        float: none;
        background-color: currentColor;
        border: 0 solid currentColor;

        &:nth-child(2) {
            animation-delay: .2s;
        }
        &:nth-child(3) {
            animation-delay: .4s;
        }
    }

    &.la-dark {
        & > div {
            color: $primary-color;
        }
    }
    &.la-gray {
        & > div {
            color: $gray-color;
        }
    }

    &.la-sm {
        &, & > div {
            width: 0.8rem;
            height: 0.8rem;
        }
    }
    &.la-2x {
        &, & > div {
            width: 3.2rem;
            height: 3.2rem;
        }
    }
    &.la-3x {
        &, & > div {
            width: 4.8rem;
            height: 4.8rem;
        }
    }
}
.loader.ball-scale-multiple {
    display: inline-block;
    // width: auto;
    // white-space: nowrap;
}

@keyframes ball-scale-multiple {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    5% {
        opacity: .75;
    }
    100% {
        opacity: 0;
        transform: scale(1);
    }
}
</style>
