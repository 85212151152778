import misc_utilities from "@/nibnut/mixins/MiscUtilities"

const handles_dynamic_tokens = {
    mixins: [misc_utilities],
    methods: {
        tokens_for_service (service_id) {
            if(service_id === this.constants("services", "DNS_FILTER").id) {
                return [
                    { id: "total_sites_blocked", label: "Total Sites Blocked" },
                    { id: "total_threats_blocked", label: "Total Threats Blocked" }
                ]
            }
            if(service_id === this.constants("services", "BITDEFENDER").id) {
                return [
                    { id: "total_files_quarantined", label: "Total Files Quarantined" }
                ]
            }
            if(service_id === this.constants("services", "ZEN_DESK").id) {
                return [
                    { id: "new_tickets", label: "Number of New Tickets (Report Month)" },
                    { id: "avg_response_time", label: "Average Response Time (Report Month)" }
                ]
            }
            return []
        }
    }
}

export default handles_dynamic_tokens
