<template>
    <nav
        :class="{ active: navigating }"
        class="nibnut-sidenav"
        v-touch:swipe="swiped"
    >
        <ul class="nav">
            <router-link
                :to="{ name: 'home' }"
                v-slot="{ href, navigate }"
                custom
            >
                <li
                    :class="{ 'active': ($route.name === 'home') || ($route.name === 'ui-catalog') }"
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        :title="$root.translate('Home')"
                        @click.native="navigate($event); $emit('navigate', false)"
                    >
                        <open-icon glyph="home" size="1x" />
                        <span>{{ $root.translate("Home") }}</span>
                    </base-link>
                </li>
            </router-link>
            <router-link
                :to="{ name: 'service_repair_order.list' }"
                v-slot="{ href, navigate, isActive }"
                custom
            >
                <li
                    :class="{ 'active': isActive }"
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        :title="$root.translate('SRO')"
                        @click.native="navigate($event); $emit('navigate', false)"
                    >
                        <open-icon glyph="tools" size="1x" />
                        <span>{{ $root.translate("SRO") }}</span>
                    </base-link>
                </li>
            </router-link>
            <router-link
                :to="{ name: 'client.list' }"
                v-slot="{ href, navigate, isActive }"
                custom
            >
                <li
                    :class="{ 'active': isActive }"
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        :title="$root.translate('Clients')"
                        @click.native="navigate($event); $emit('navigate', false)"
                    >
                        <open-icon glyph="headset" size="1x" />
                        <span>{{ $root.translate("Clients") }}</span>
                    </base-link>
                </li>
            </router-link>
            <router-link
                :to="{ name: 'reports' }"
                v-slot="{ href, navigate, isActive }"
                custom
            >
                <li
                    :class="{ 'active': isActive }"
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        :title="$root.translate('Reports')"
                        @click.native="navigate($event); $emit('navigate', false)"
                    >
                        <open-icon glyph="file-invoice-dollar" size="1x" />
                        <span>{{ $root.translate("Reports") }}</span>
                    </base-link>
                </li>
            </router-link>
            <router-link
                v-if="is_at_least_administrator"
                :to="{ name: 'user.list' }"
                v-slot="{ href, navigate, isActive }"
                custom
            >
                <li
                    :class="{ 'active': isActive }"
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        :title="$root.translate('Users')"
                        @click.native="navigate($event); $emit('navigate', false)"
                    >
                        <open-icon glyph="users" size="1x" />
                        <span>{{ $root.translate("Users") }}</span>
                    </base-link>
                </li>
            </router-link>
            <router-link
                v-if="is_at_least_administrator"
                :to="{ name: 'settings' }"
                v-slot="{ href, navigate, isActive }"
                custom
            >
                <li
                    :class="{ 'active': isActive }"
                    class="nav-item"
                >
                    <base-link
                        :href="href"
                        :title="$root.translate('Settings')"
                        @click.native="navigate($event); $emit('navigate', false)"
                    >
                        <open-icon glyph="cog" size="1x" />
                        <span>{{ $root.translate("Settings") }}</span>
                    </base-link>
                </li>
            </router-link>
            <li
                v-if="installable"
                class="nav-item"
            >
                <base-link
                    href="#"
                    @click.prevent="$emit('install')"
                >
                    <open-icon glyph="arrow-circle-down" size="1x" />
                    <span>{{ $root.translate("Install") }}</span>
                </base-link>
            </li>
        </ul>
        <div class="nibnut-sidenav-footer">
            <div v-if="!navigating">
                &copy;&nbsp;{{ current_year }}<br />
                v.{{ app_version }}
            </div>
            <div v-else>
                &copy;&nbsp;{{ current_year }}&nbsp;{{ setting("customer_name") }}<br />
                {{  $root.translate("App sprouted from a") }}&nbsp;<a href="https://nibnut.com"><base-image :src="nibnut_logo" alt="nibnut" /></a>&nbsp;&bull;&nbsp;v.{{ app_version }}
            </div>
        </div>
    </nav>
</template>

<script>
import { profile_utilities, misc_utilities, string_utilities } from "@/nibnut/mixins"

import { BaseImage, BaseLink, OpenIcon } from "@/nibnut/components"

import nibnut_logo from "@/assets/img/nibnut.png"

export default {
    mixins: [profile_utilities, misc_utilities, string_utilities],
    components: {
        BaseImage,
        BaseLink,
        OpenIcon
    },
    methods: {
        swiped (direction) {
            if((direction === "left") || (direction === "right")) this.$emit("navigate", (direction === "right"))
        }
    },
    computed: {
        nibnut_logo () {
            return nibnut_logo
        }
    },
    props: {
        navigating: {
            type: Boolean,
            required: true
        },
        installable: {
            type: Boolean,
            required: false
        }
    }
}
</script>
